<template>
    <div>
        <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="单位:">
                <el-select v-model="search.institutionId" size="medium" clearable @change="handleInstitution"
                    placeholder="请选择单位" class="form-line-item">
                    <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                        :value="item.institutionId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="批次:">
                <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次" class="form-line-item"
                    :disabled='batchShow'>
                    <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                        :value="item.batchId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="margin-left:10px;">
                <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                    搜索
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                    重置
                </el-button>
            </el-form-item>
        </el-form>
        <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar totalPageTable" round align="left"
            ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
            :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading' :data="paperList">
            <!-- <vxe-column type='checkbox' width="50" :show-overflow="'tooltip'"></vxe-column> -->
            <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
            <vxe-column field="institutionName" title="单位" min-width="200" :show-overflow="'tooltip'"></vxe-column>
            <vxe-column field="batchName" title="批次" min-width="200" :show-overflow="'tooltip'"></vxe-column>
            <vxe-column field="willSendNum" title="待送审" width="100" :show-overflow="'tooltip'" sortable></vxe-column>
            <vxe-column field="sendingNum" title="送审中" width="100" :show-overflow="'tooltip'" sortable></vxe-column>
            <vxe-column field="reviewingNum" title="评审中" width="100" :show-overflow="'tooltip'" sortable></vxe-column>
            <vxe-column field="submitNum" title="已完成" width="100" :show-overflow="'tooltip'" sortable></vxe-column>
            <vxe-column field="rejectNum" title="已拒绝" width="100" :show-overflow="'tooltip'" sortable></vxe-column>
            <vxe-column field="revokeNum" title="已撤销" width="100" :show-overflow="'tooltip'" sortable></vxe-column>
            <vxe-column field="totalNum" title="总数" width="100" :show-overflow="'tooltip'" sortable></vxe-column>
        </vxe-table>
        <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
            :total="page.total" :page-sizes="page.pageSizes"
            :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
            @page-change="handlePageChange">
            <template #left>
                <vxe-button size="small" @click="firstPage">首页
                </vxe-button>
            </template>
        </vxe-pager>
    </div>
</template>

<script>
    export default {
        name: 'totalPage',
        data() {
            return {
                search: {
                    institutionId: '',
                    batchId: '',
                },
                //论文列表
                paperList: [{
                    institutionName: '南京理工大学',
                    batchName: '2021硕士第一批',
                    willSendNum: 0,
                    sendingNum: 0,
                    reviewingNum: 0,
                    submitNum: 0,
                    rejectNum: 0,
                    revokeNum: 0,
                    totalNum: 0
                }, {
                    institutionName: '南京理工大学',
                    batchName: '2021硕士第一批',
                    willSendNum: 0,
                    sendingNum: 0,
                    reviewingNum: 0,
                    submitNum: 0,
                    rejectNum: 0,
                    revokeNum: 0,
                    totalNum: 0
                }],
                //分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [1, 5, 10, 15, 20],
                    total: 100
                },
            }
        },
        methods: {
            batchSearch() {},
            resetHandle() {
                this.search = {
                    institutionId: '',
                    batchId: '',
                };
            },
        },
        components: {

        }
    }
</script>

<style>
    .totalPageTable .vxe-table--body-wrapper {
        height: calc(100vh - 303px);
    }
</style>