<template>
    <div class="app-container">
        <!-- <el-tabs> -->
            <!-- <el-tab-pane label="论文视角"> -->
                <paper-page></paper-page>
            <!-- </el-tab-pane> -->
            <!-- <el-tab-pane label="评阅书视角">
                <review-page></review-page>
            </el-tab-pane>-->
            <!-- <el-tab-pane label="专家视角">
                <expert-page></expert-page>
            </el-tab-pane> -->
            <!-- <el-tab-pane label="总体视角">
                <total-page></total-page>
            </el-tab-pane>  -->
        <!-- </el-tabs> -->
    </div>
</template>

<script>
    import PaperPage from '@/components/p2p/PaperPage'
    import ReviewPage from '@/components/p2p/ReviewPage'
    import ExpertPage from '@/components/p2p/ExpertPage'
    import totalPage from '@/components/p2p/totalPage'
    export default {
        name: 'p2p',
        data() {
            return {

            }
        },
        methods: {

        },
        components: {
            PaperPage,
            ReviewPage,
            ExpertPage,
            totalPage
        }
    }
</script>

<style scoped>

</style>